@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

$max-width: 1224 * tokens.$bpk-one-pixel-rem;
$sidebar-width: 300 * tokens.$bpk-one-pixel-rem;

.FeatureContainer {
  .FeatureItem {
    &:not(:last-child) {
      margin-bottom: 2 * tokens.bpk-spacing-xl();

      @include breakpoints.bpk-breakpoint-above-mobile {
        margin-bottom: 3 * tokens.bpk-spacing-xl();
      }
    }

    &:last-child {
      margin-bottom: tokens.bpk-spacing-lg();

      @include breakpoints.bpk-breakpoint-above-mobile {
        margin-bottom: tokens.bpk-spacing-xxl();
      }

      div[class*='FlightPricesDisclaimerText'] {
        padding: 0;
      }
    }

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.sitemap-wrapper) & {
      margin-bottom: 0;
    }
  }

  .HeaderItem {
    width: 100%;
  }

  .MainSection {
    max-width: $max-width;
    margin: 0 auto;

    @include breakpoints.bpk-breakpoint-mobile {
      max-width: 100%;
      padding: 0 tokens.bpk-spacing-base();
    }

    @media screen and (max-width: $max-width) and (min-width: tokens.$bpk-breakpoint-mobile) {
      max-width: 100%;
      padding: 0 tokens.$bpk-breakpoint-small-tablet-margin;
    }

    .Main {
      @media screen and (max-width: $max-width) {
        max-width: 100%;
      }
    }
  }

  .MainSectionWithAD {
    display: flex;
    width: $max-width;
    margin: 0 auto;

    @include breakpoints.bpk-breakpoint-mobile {
      max-width: 100%;
      padding: 0 tokens.bpk-spacing-base();
    }

    @media screen and (max-width: $max-width) and (min-width: tokens.$bpk-breakpoint-mobile) {
      max-width: 100%;
      padding: 0 tokens.$bpk-breakpoint-small-tablet-margin;
    }

    .Main {
      width: 924 * tokens.$bpk-one-pixel-rem;

      @include breakpoints.bpk-breakpoint-tablet {
        max-width: 100%;
      }

      @include breakpoints.bpk-breakpoint-above-tablet {
        padding-right: tokens.bpk-spacing-xl();
      }
    }

    .Sidebar {
      width: $sidebar-width;

      @include breakpoints.bpk-breakpoint-tablet {
        display: none;
      }
    }
  }
}
